import React, {useState, useEffect} from 'react';
import * as classes from './TeamSection.module.css';
import Arrow from '../../assets/images/others/Group1button.png';

import Paul from '../../assets/images/team/paul.png';
import Saudamini from '../../assets/images/team/saudamini.png';
import Aadi from '../../assets/images/team/aadi.png';
import Abhishek from '../../assets/images/team/abhishek.png';
import Sarah from '../../assets/images/team/sarah.png';
import Aniket from '../../assets/images/team/aniket.png';
import Rishika from '../../assets/images/team/rishika.png';

import Paul_webp from '../../assets/images/team/paul.webp';
import Saudamini_webp from '../../assets/images/team/saudamini.webp';
import Aadi_webp from '../../assets/images/team/aadi.webp';
import Abhishek_webp from '../../assets/images/team/abhishek.webp';
import Sarah_webp from '../../assets/images/team/sarah.webp';
import Aniket_webp from '../../assets/images/team/aniket.webp';
import Rishika_webp from '../../assets/images/team/rishika.webp';
import Dip from '../../assets/images/team/Dip.png';
import Dip_webp from '../../assets/images/team/Dip.webp';

import Header from '../../components/Header/Header';

import { Link } from "react-scroll";
import Content from '../../../content.json'

const TeamSection = () => {

	const isBrowser = () => typeof window !== "undefined";

	const [mobile, setMobile] = useState(false);
	useEffect(() => {
		if(isBrowser){
			if(window.innerWidth > 768){
				setMobile(false)
			}else{
				setMobile(true)
			}
		}		
	},[])

	function support_format_webp(){
	  const isBrowser = () => typeof window !== "undefined";
	  if(isBrowser()){
	      var elem = document.createElement('canvas');

	      if (!!(elem.getContext && elem.getContext('2d')))
	      {
	          // was able or not to get WebP representation
	          return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
	      }
	      else
	      {
	          // very old browser like IE 8, canvas not supported
	          return false;
	      }
	  }else{
	    return false;
	  }
	}

	const paul_selected = support_format_webp() ? Paul_webp : Paul;
	const saudamini_selected = support_format_webp() ? Saudamini_webp : Saudamini;
	const aadi_selected = support_format_webp() ? Aadi_webp : Aadi;
	const abhishek_selected = support_format_webp() ? Abhishek_webp : Abhishek;
	const sara_selected = support_format_webp() ? Sarah_webp : Sarah;
	const aniket_selected = support_format_webp() ? Aniket_webp : Aniket;
	const rishika_selected = support_format_webp() ? Rishika_webp : Rishika;
	const dip_selected = support_format_webp() ? Dip : Dip_webp;

	return (
		<>
		<Header />
		<div className={classes.TeamSection} id="team-section">
			<div className={classes.ScrollContainer}>
				<div className={classes.TeamContainer}>
					<div className={classes.Text}>
						<span>{Content.teamPage.heading.split(' ')[0]}</span> {Content.teamPage.heading.split(' ').slice(1).join(' ')}
						<div className={classes.ScrollText}>
							{Content.teamPage.subHeading}
							<img src={Arrow} alt="" />
						</div>
						<div className={[classes.ScrollText, classes.ScrollTextMobile].join(' ')}>
							<Link
				                activeClass={classes.Active}
				                to="EmailSection"
				                spy={true}
				                smooth={true}
				                offset={0}
				                duration={1000}
					        ><img src={Arrow} alt="" /></Link>
						</div>
					</div>
					<div className={classes.TeamPictures}>
						<div className={classes.TeamPicturesChild}>
							<div className={classes.ImageContainer}>
								<img src={paul_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["paul"].name}</div>
									<div>{Content.teamPage.team["paul"].designation}</div>
								</div>
							</div>
							{ mobile ?
							<div className={classes.ImageContainer}>
								<img src={saudamini_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["saudamini"].name}</div>
									<div>{Content.teamPage.team["saudamini"].designation}</div>
								</div>
							</div> : null }
							<div className={classes.ImageContainer}>
								<img src={abhishek_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["abhishek"].name}</div>
									<div>{Content.teamPage.team["abhishek"].designation}</div>
								</div>
							</div>
							{ !mobile ?
							<div className={classes.ImageContainer}>
								<img src={saudamini_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["saudamini"].name}</div>
									<div>{Content.teamPage.team["saudamini"].designation}</div>
								</div>
							</div> : null }
							<div className={classes.ImageContainer}>
								<img src={aniket_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["aniket"].name}</div>
									<div>{Content.teamPage.team["aniket"].designation}</div>
								</div>
							</div>
							<div className={classes.ImageContainer}>
								<img src={rishika_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["rishika"].name}</div>
									<div>{Content.teamPage.team["rishika"].designation}</div>
								</div>
							</div>
							<div className={classes.ImageContainer}>
								<img src={sara_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["sara"].name}</div>
									<div>{Content.teamPage.team["sara"].designation}</div>
								</div>
							</div>
							<div className={classes.ImageContainer}>
								<img src={dip_selected} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.team["dip"].name}</div>
									<div>{Content.teamPage.team["dip"].designation}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*<div className={[classes.TeamContainer, classes.AdvisorsTeam].join(' ')} id="advisors_team">
					<div className={classes.Text}>
						{Content.teamPage.advisors_heading}
					</div>
					<div className={classes.TeamPictures}>
						<div>
							<div className={classes.ImageContainer}>
								<img src={Paul} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.advisors_team["1"].name}</div>
									<div>{Content.teamPage.advisors_team["1"].designation}</div>
								</div>
							</div>
							<div className={classes.ImageContainer}>
								<img src={Aadi} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.advisors_team["3"].name}</div>
									<div>{Content.teamPage.advisors_team["3"].designation}</div>
								</div>
							</div>
							<div className={classes.ImageContainer}>
								<img src={Abhishek} />
								<div className={classes.Overlay}>
									<div>{Content.teamPage.advisors_team["2"].name}</div>
									<div>{Content.teamPage.advisors_team["2"].designation}</div>
								</div>
							</div>
						</div>
					</div>
				</div>*/}
			</div>
		</div>
		</>
	);
}

export default TeamSection;