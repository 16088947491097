// extracted by mini-css-extract-plugin
export var TeamSection = "TeamSection-module--TeamSection--OcJft";
export var ScrollContainer = "TeamSection-module--ScrollContainer--2JvlB";
export var TeamContainer = "TeamSection-module--TeamContainer--4M97n";
export var Text = "TeamSection-module--Text--2u0kB";
export var TextContainer = "TeamSection-module--TextContainer--2e8fC";
export var TeamPictures = "TeamSection-module--TeamPictures--2bUk2";
export var ImageContainer = "TeamSection-module--ImageContainer--mgvpS";
export var Overlay = "TeamSection-module--Overlay--1SPkn";
export var ScrollText = "TeamSection-module--ScrollText--1K8iW";
export var ScrollTextMobile = "TeamSection-module--ScrollTextMobile--iJ2TV";
export var Active = "TeamSection-module--Active--3FyxH";
export var ScrollArrow = "TeamSection-module--ScrollArrow--2KBSY";
export var AddAnimation = "TeamSection-module--AddAnimation--36sbi";
export var TopDown = "TeamSection-module--TopDown--1Ogyv";
export var ZeroOpacity = "TeamSection-module--ZeroOpacity--1T8Fd";